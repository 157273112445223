import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';

import { processVariables } from '../lib';

const wrapperStyles = {
  width: '100%',
};

const containerStyles = {
  display: 'flex',
  'flex-direction': 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

const contentStyles = {
  width: '100%',
  maxWidth: '80%',
  padding: '0 2rem',
};

type Props = {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          html: string;
          id: string;
          frontmatter: {
            title: string;
          };
        };
      }[];
    };
  };
};

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== 'undefined';

const PrivacyPolicy: FC<Props> = ({ data }) => {
  const params = useMemo(() => {
    if (isBrowser) {
      //info: Get query param
      const params = new URLSearchParams(window.location.search);
      return Object.fromEntries(params.entries());
    }
    return null;
  }, [!!isBrowser]);

  //info: Markdawn files in edges
  const { allMarkdownRemark } = data;
  const { edges } = allMarkdownRemark;

  if (!params) {
    return <div />;
  }

  return (
    <div style={wrapperStyles}>
      <div style={containerStyles}>
        {Array.isArray(edges) &&
          edges
            .sort(
              (a, b) =>
                Number(a.node.frontmatter.title) -
                Number(b.node.frontmatter.title)
            )
            .map(({ node }) => (
              <React.Fragment key={node.id}>
                <div
                  style={contentStyles}
                  dangerouslySetInnerHTML={{
                    __html: processVariables(node.html, params),
                  }}
                />
                <br />
              </React.Fragment>
            ))}
      </div>
    </div>
  );
};

export default PrivacyPolicy;

export const pageQuery = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            title
          }
          html
        }
      }
    }
  }
`;
